import React, { PropsWithChildren } from 'react';
import * as CTAStyles from '@/components/sections/CTASection/CTASection.module.scss';
import SectionRichText from '../SectionRichText';
import type { GradientListTypes } from './GradientList.types';
import * as styles from './GradientList.module.scss';

export default function GradientList({ steps, title, cta, children }: PropsWithChildren<GradientListTypes>) {
  return (
    <>
      <section className={styles.wrapper}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          {steps && (
            <ul className={styles.stepsList}>
              {steps.map(({ _uid, body, image }) => (
                <li key={_uid} className={styles.stepsListItem}>
                  {body && <SectionRichText body={body} className={styles} arrowLinks />}
                  {image && (
                    <div className={styles.imageWrapper}>
                      <img
                        className={styles.image}
                        src={image.replace('//a.storyblok.com', '//web-assets2.delphix.com')}
                        alt={image}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={styles.childrenWrapper}>{children}</div>
      </section>
      {cta && (
        <div className={styles.ctaWrapper}>
          <div className={styles.cta}>
            <SectionRichText body={cta} className={CTAStyles} />
          </div>
        </div>
      )}
    </>
  );
}
